import React from 'react'
import Layout from '../../components/Global/Layout'
import Loader from '../../components/Global/Loader'
import { graphql } from 'gatsby'
import Articles from '../../components/Articles'
import _ from 'lodash'

const locale = 'en-US'

export const query = graphql`
  {
    allContentfulKnowledgeBaseArticle(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          title
          type
          image {
            fixed(width: 183, height: 132, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
          slug
          updatedAt(formatString: "MMMM Do, YYYY")
          node_locale
        }
      }
    }

    allContentfulKnowledgeBaseLanding {
      edges {
        node {
          title
          subtitle
          articlesListHeading
          topQuestionsHeading
          trademarkQuestions {
            internal {
              content
            }
          }
          readMoreButton
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }

    allContentfulWhyUs {
      edges {
        node {
          button
          buttonLink
          description {
            description
          }
          title
          node_locale
        }
      }
    }
  }
`

const KnowledgeBaseLanding = (props) => {
  if (!locale) {
    return (
      <Layout url='/articles' locale={locale}>
        <Loader loading />
      </Layout>
    )
  }
  const filteredData =
    props.data?.allContentfulKnowledgeBaseArticle.edges?.filter((node) => {
      return _.startsWith(node.node.slug, `/${locale.toLowerCase()}`)
    })

  const landingPageData =
    props.data?.allContentfulKnowledgeBaseLanding?.edges.filter(
      (node) => node.node.node_locale === locale
    )[0]?.node
  const WhyUsData = props.data?.allContentfulWhyUs?.edges.filter(
    (node) => node.node.node_locale === locale
  )[0]?.node

  return (
    <Layout
      locale={locale}
      url='/articles'
      title={JSON.parse(landingPageData?.seoContent.internal.content).title}
      desc={JSON.parse(landingPageData?.seoContent.internal.content).desc}
    >
      <Articles
        locale={locale}
        data={filteredData}
        landingPageData={landingPageData}
        WhyUsData={WhyUsData}
      />
    </Layout>
  )
}

export default KnowledgeBaseLanding
